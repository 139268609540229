import React from 'react';
import './AreAliensRealStyles.css';
import { SiAlienware } from "react-icons/si";



const AreAliensReal = () => {
    return  (
        <div className='arealiensreal'>
            <div className='container'>
                <div className='content'>
                    <h2><SiAlienware className='iconmain' /><span> Join the</span> Exo Solaria Union</h2>
                    <p>
                    Join Form
                    </p>

                   
                    
                    
                </div>
            </div>

        </div>
    )
}

export default AreAliensReal;